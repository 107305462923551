import { render, staticRenderFns } from "./Trace.vue?vue&type=template&id=67ade8cf&scoped=true&"
import script from "./Trace.vue?vue&type=script&lang=js&"
export * from "./Trace.vue?vue&type=script&lang=js&"
import style0 from "./Trace.vue?vue&type=style&index=0&id=67ade8cf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67ade8cf",
  null
  
)

export default component.exports