<template>
  <div class="multiple-ratings-item">
    <div class="header-container">
      <div class="avg-rating-title">{{ title }}</div>
      <div>
        <span class="avg-rating">{{ avgCount }}</span>
        <span class="avg-rating-total">{{" "}}/ 5</span>
      </div>
      <v-rating
        class="v-rating-add v-rating-read-only"
        :value="avgCount"
        readonly
        half-increments
      ></v-rating>
      <div class="avg-rating-details">{{ `${totalRatings} Ratings`}}</div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center;">
      <div class="overview-container">
        <div class="list-item" v-for="(entry, index) in Object.values(overview)" :key="index">
          <div class="list-item-child">{{ Object.values(overview).length - index }}</div>
          <div class="list-item-child"><v-icon class="individual-star">mdi-star</v-icon></div>
          <div class="list-item-child progress-bar-container">
            <div class="progress-bar" :style="`width:${entry*100/totalRatings}%`"></div>
          </div>
          <div class="entity-value">{{ entry }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RatingSummery",
  props: {
    title: {
      type: String,
      default: ''
    },
    totalRatings: {
      type: [Number, String],
      default: 0
    },
    avgCount: {
      type: [Number, String],
      default: 0
    },
    overview: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
  .multiple-ratings-item {
    padding: 10px 20px;
    border: 1px solid $primaryDarkest;
    border-radius: 8px;
    margin-bottom: 10px;
    width: fit-content;
    height: fit-content;
  }
  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .overview-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    margin-top: 10px;
  }
  .list-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1px 0px;
  }
  .list-item-child {
    margin: 0px 10px 0px 0px;
    color: $primaryDarkest;
  }
  .entity-value {
    margin-left: auto;
    color: $primaryDarkest;
  }
  .avg-rating-title {
    font-size: 16px;
    color: $primaryDarkest;
    width: fit-content;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .avg-rating {
    font-size: 24px;
    color: $primaryDarkest;
    font-weight: 500;
  }
  .avg-rating-total {
    font-size: 16px;
    color: gray;
  }
  .avg-rating-details {
    margin-top: 5px;
    font-size: 14px;
    color: $primaryDarkest;
  }
  .individual-star {
    color: #19BEBB;
  }
  .progress-bar-container {
    width: 100px;
    background-color: #D1E0E0;
  }
  .progress-bar {
    background-color:#19BEBB;
    height:12px;
  }
  .v-rating-add::v-deep .mdi-star {
    color: #19BEBB !important;
    font-size: 34px;
  }
  .v-rating-add::v-deep .v-icon {
    padding: 0 3px !important;
  }
  .v-rating-add::v-deep .mdi-star-outline {
    color: #19BEBB !important;
    font-size: 34px;
  }
  .v-rating-add::v-deep .mdi-star-half-full {
    color: #19BEBB !important;
    font-size: 34px;
  }
  .v-rating-read-only {
    width: fit-content;
    margin-left: -5px;
  }
  .v-rating-read-only::v-deep .mdi-star {
    font-size: 24px;
  }
  .v-rating-read-only::v-deep .mdi-star-outline {
    font-size: 24px;
  }
  .v-rating-read-only::v-deep .mdi-star-half-full {
    font-size: 24px;
  }
</style>
