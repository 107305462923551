<template>
    <v-container class="parent-container" fluid>
        <div v-if="notFoundTrace" style="height: 100%; width: 100%;"><NotFound /></div>
        <div v-else-if="isPendingTrace" style="height: 100%; width: 100%;"><TracePending /></div>
        <div v-else-if="loading" class="text-center" style="margin: auto;">
            <md-progress-spinner class="color_stroke" md-mode="indeterminate"></md-progress-spinner>
        </div>
        <div v-else class="child-container">
            <v-row>
                <v-col>
                    <div class="powered-by">
                        <div class="powered-by-label">Powered By</div>
                        <v-spacer></v-spacer>
                        <img v-if="!linkClick" src="../../assets/seamless-source-banner.png" alt="Seamless Source" />
                        <a v-else href="https://seamlesssource.com/">
                            <img src="../../assets/seamless-source-banner.png" alt="Seamless Source" />
                        </a>
                    </div>
                </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="traceability">360 Trace</div>
                <div v-if="buyerLogoImg" class="buyer-logo" >
                    <img v-if="!linkClick || !buyerLink" class="logo-img" :src="buyerLogoImg" alt="buyerLogo"/>
                    <a v-else target="_blank" :href="`//${buyerLink.split('//').pop()}`">
                        <img class="logo-img" :src="buyerLogoImg" alt="buyerLogo" />
                    </a>
                </div>
                <hr class="hr_devider_horizontal"/>
                <div class="product-name">{{productName}}</div>
                <div style="margin-top:-5px; margin-bottom: 20px;" v-if="showToggle">
                    <span class="switch btn-color-mode-switch">
                        <input type="checkbox" name="version" id="version" value="1" v-model="versionChecked">
                        <label
                            for="version"
                            data-on="Current Version"
                            data-off="Updated Version"
                            class="btn-color-mode-switch-inner"
                        >
                            </label>
                    </span>
                </div>
                <div v-if="!linkClick && publicLink && (!showToggle || (showToggle && currentVersion))">
                    <div class="public-link" >
                        Get sharable public link
                    </div>
                    <div class="url-div">
                        <v-icon style="color: #501D5F; transform: rotate(120deg);">link</v-icon>
                        <span class="url-border"></span>
                        <span class="url-span" id="toCopy">{{publicLink}}</span><md-button :class="copied ? 'copy-class' : 'copied-class'" @click="copying">{{copied ? "Copied!" : "Copy"}}</md-button>
                    </div>
                </div>
                <div v-if="qr && (!showToggle || (showToggle && currentVersion))">
                    <qr-code :qr="qr" :trackingCode="trackingCode" />
                </div>
              </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div class="embed">
                        <img
                            class="centered-and-cropped"
                            :src="
                                defaultImage
                                ? `${baseUrl}/api/inquiries/file_show/${defaultImage}`
                                : sampleImage
                            "
                            alt="sample image"
                        />
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="bomItems && bomItems.length">
                <v-col>
                    <v-app>
                        <div class="more-info-container">
                            <div v-for="bomItem in bomItems"
                                :key="bomItem.id"
                            >
                                <bom-item-trace :bomItem="bomItem" ></bom-item-trace>
                            </div>
                        </div>
                    </v-app>
                </v-col>
            </v-row>
            <v-row v-if="fabricComposition">
                <v-col>
                    <div class="more-info-container">
                        <div class="icon-container title-underline">
                            <fabric-composition-icon fill="#70D0CE"/>
                            <span class="more-info-title">Fabric Composition</span>
                        </div>
                        <div class="more-info-data accept-line-breaks">
                            {{fabricComposition}}
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="careInstructions.length">
                <v-col>
                    <div class="more-info-container">
                        <div class="icon-container title-underline">
                            <care-instruction-icon fill="#70D0CE" :width="24" :height="24"/>
                            <span class="more-info-title">Care Instructions</span>
                        </div>
                        <div class="more-info-data">
                            <div style="min-height: 40px; margin-bottom:5px width: 85%;">
                                <span v-for="(careInstruction, index) in careInstructions" :key="`careInstruction${index}`" >
                                    <img class="care-instruction-img" v-if="careInstruction.icon" :src="careInstruction.icon"/>
                                </span>
                            </div>
                            <div v-for="(careInstruction, index) in careInstructions" :key="`careInstruction${index}`">
                                <span>{{careInstruction.care_instruction_name}}</span>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div v-if="displaySupplyChain" class="more-info-container supply-chain-label">
                        <div class="icon-container title-underline">
                            <supply-chain-icon fill="#70D0CE" :width="24" :height="24"/>
                            <span class="more-info-title">Supply Chain</span>
                        </div>
                    </div>
                    <div v-if="displaySupplyChain" class="trace-container">
                        <div v-for="(trace, index) in traceDetails" :key="`trace${index}`" >
                            <div v-if="trace.country || trace.delivery_method">
                                <div class="icon-container">
                                    <trace-services-icon :traceName="trace.icon" :supplierStatus="trace.supplier_status"/>
                                    <span class="title" :class="trace.supplier_status && trace.supplier_status === 1 ? 'verified_title' : 'unverified_title'">
                                        {{trace.field_name.toUpperCase()}}
                                    </span>
                                </div>
                                <v-row>
                                    <div v-if="stepperShow(index)" class="stepper"></div>
                                    <v-col cols="12">
                                        <div class="content">
                                            <div class="data-field" v-if="trace.country" :class="!trace.supplier_status || trace.supplier_status !== 1 ? 'unverified_details_subtitle' : 'verified_details_subtitle'">
                                                Country:
                                                <span :class="!trace.supplier_status || trace.supplier_status !== 1 ? 'unverified_details_data' : 'verified_details_data'">
                                                    {{trace.country}}
                                                </span>
                                            </div>
                                            <div class="data-field" v-if="trace.region" :class="!trace.supplier_status || trace.supplier_status !== 1 ? 'unverified_details_subtitle' : 'verified_details_subtitle'">
                                                Region:
                                                <span :class="!trace.supplier_status || trace.supplier_status !== 1 ? 'unverified_details_data' : 'verified_details_data'">
                                                    {{trace.region}}
                                                </span>
                                            </div>
                                            <div class="data-field" v-if="trace.number_of_employees" :class="!trace.supplier_status || trace.supplier_status !== 1 ? 'unverified_details_subtitle' : 'verified_details_subtitle'">Employees: <span class="data">{{trace.number_of_employees}} {{ formatEmployees(trace) }}</span></div>
                                            <div class="data-field" v-if="trace.field_name === 'Delivery'" :class="!trace.supplier_status || trace.supplier_status !== 1 ? 'unverified_details_subtitle' : 'verified_details_subtitle'">
                                                Method:
                                                <span :class="!trace.supplier_status || trace.supplier_status !== 1 ? 'unverified_details_data' : 'verified_details_data'">
                                                    {{deliveryMethods.find(element => trace.delivery_method === element.id).name}}
                                                </span>
                                            </div>
                                            <div v-if="trace.certifications.length">
                                                <div class="data-field" :class="!trace.supplier_status || trace.supplier_status !== 1 ? 'unverified_details_subtitle' : 'verified_details_subtitle'">
                                                    Certifications:
                                                </div>
                                                <div class="certifications" tabindex="0">
                                                    <div :class="certification.verified ? 'verified-certification-list' : 'unverified-certification-list'" v-for="(certification, index) in trace.certifications" :key="`trace.certifications${index}`">
                                                        <div v-if="!certification.description">{{certification.name}}</div>
                                                        <v-tooltip v-else top :color="certification.verified ? '#473068' : '#EA7A66'">
                                                            <template v-slot:activator="{ on, attrs }">

                                                                <div data-app>
                                                                    <div v-bind="attrs" v-on="on">{{certification.name}}</div>
                                                                </div>
                                                            </template>
                                                            <div class="description-child-container">
                                                                <div class="mt-1">{{ certification.description }}</div>
                                                            </div>
                                                        </v-tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                    <div class="more-info-container" v-if="sustainability">
                        <div class="icon-container title-underline">
                            <sustainability-icon fill="#70D0CE" :width="28" :height="28"/>
                            <span class="more-info-title">Sustainability, Circularity & Repairs</span>
                        </div>
                        <div class="more-info-data accept-line-breaks" v-html="sustainability" v-linkified>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <div v-if="(displaySupplyChain) || (bomItems && bomItems.length)" class="statuses">
                <div class="supplier-status" v-for="(status, index) in statuses" :key="`statuses${index}`">
                    <v-tooltip top :color="status === 'Verified' ? '#473068' : '#EA7A66'">
                    <template v-slot:activator="{ on, attrs }">
                        <div data-app>
                        <div v-bind="attrs" v-on="on">
                            <div>
                                <v-icon :style="status === 'Verified' ? 'color: #473068; margin-top: -5px;' : 'color: #EA7A66; margin-top: -5px;'">circle</v-icon> <span style="color:#473068; margin-top:20px; margin-left:15px;"> {{" " + status}}</span>
                            </div>
                            </div>
                        </div>
                    </template>
                    <div class="description-child-container">
                        <div class="mt-1">{{status === 'Verified'
                            ? ' Seamless Source registered and verified suppliers.The transactions happened through the Seamless Source platform. Where necessary the certifications were verified with the certification bodies.'
                            : 'The suppliers have completed the transactions out of the Seamless Source platform. Information was collected from the relevant immediate Seamless Source registered suppliers.'}}</div>
                    </div>
                    </v-tooltip>
                </div>
            </div>
            <v-row v-if="isReviewComponentVisible">
                <v-col cols="12">
                    <div class="more-info-container">
                        <div class="icon-container title-underline">
                            <customer-feedback-icon fill="#70D0CE" :width="28" :height="28" />
                            <span class="more-info-title">Customer Feedback</span>
                        </div>
                        <review
                            :isAddReviewVisible="isAddReviewVisible"
                            :styleNumber="$route.params.styleNumber"
                            :isMobile="true"
                        />
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import linkify from "vue-linkify"
import restAdapter from "../../restAdapter";
import NotFound from "../../views/NotFound.vue";
import TracePending from "../../views/inquiry/TracePending.vue";
import QrCode from '../../components/Inquiry/QrCode.vue';
import Review from "./Review.vue";
import Vue from 'vue';
import BomItemTrace from '../Inquiry/BomItemTrace.vue';
import * as conf from "../../conf.yml";

Vue.directive('linkified', linkify)

export default {
    name: "TraceMobile",

    components: {
        NotFound,
        TracePending,
        QrCode,
        BomItemTrace,
        Review,
    },

    props: {
        customerWindow: {
            type: Boolean,
            default: false,
        },
        styleNumber: {
            type: String,
            default: null,
        },
        publicLink:{
            type: String,
            default: null,
        },
        traceLink:{
            type: String,
            default: null,
        },
        linkClick:{
            type: Boolean,
            default: true,
        },
        currentVersion:{
            type: Boolean,
            default: true,
        },
        showToggle:{
            type: Boolean,
            default: false,
        },
        qr:{
            type: String,
            default: null,
        },
        trackingCode: {
            type: String,
            default: null,
        },
        isReviewComponentVisible: {
            type: Boolean,
            default: true,
        },
        isAddReviewVisible:{
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        traceDetails: [],
        notFoundTrace: false,
        isPendingTrace: false,
        loading: true,
        showRatio: false,
        productName: "",
        deliveryMethods: [],
        statuses: ['Verified','Unverified'],
        copied:false,
        versionChecked:false,
        fabricComposition:null,
        careInstructions:[],
        sustainability:null,
        buyerLogoImg:null,
        buyerLink:null,
        bomItems: [],
        defaultImage: null,
        sampleImage: require(`../../assets/trace/default.svg`),
        baseUrl: conf.default.server.host,
    }),
    watch:{
        traceLink:{
            handler: function() {
                this.getTraceDetails();
            },
            immediate: true
        },
        versionChecked:{
            handler: function(value) {
                this.changeVersion(value);
            },
            immediate: true
        }
    },
    computed: {
        displaySupplyChain(){
            return (
                this.traceDetails &&
                this.traceDetails.length &&
                this.traceDetails.find(trace => (trace.country || trace.delivery_method))
            );
        }
    },
    methods: {
        formatEmployees(employees){
            const values = [employees.number_of_males, employees.number_of_females, employees.number_of_others];
            const genders = ["Males","Females","Others"];
            let percentage = "";

            for (let i = 0; i < values.length; i++){
                percentage = `${percentage}${values[i] ? values[i] + '%' + ' ' + genders[i] : ''}`;
                if(values[i+1] && percentage){
                percentage = `${percentage} | `
                }
            }
            if (percentage){
            percentage = `(${percentage})`
            }
            return percentage;
        },
        getTraceDetails(){
            this.loading = true;
            const url = this.customerWindow ? this.traceLink : "/api/inquiries/trace/" + this.$route.params.styleNumber;
            restAdapter
                .get(url)
                .then(response => {
                    this.traceDetails = response.data.details;
                    this.bomItems = response.data.bom_records;
                    this.productName = response.data.product_name;
                    this.supplierStatuses = response.data.supplier_statuses;
                    this.deliveryMethods = response.data.delivery_methods;
                    this.fabricComposition = response.data.fabric_composition;
                    this.careInstructions = response.data.care_instructions;
                    this.sustainability = response.data.sustainability_and_circularity;
                    this.buyerLogoImg = response.data.buyer_logo;
                    this.buyerLink =  response.data.buyer_link;
                    this.defaultImage = response.data.default_image;
                    this.loading = false;
                })
                .catch(error => {
                    if(error.response.status === 404){
                        this.notFoundTrace = true;
                    }
                    else{
                        this.isPendingTrace = true;
                    }
                    this.loading = false;
                });
        },
        calculatePrecentage(value, total){
            return Math.round(value * 100 / total);
        },
        copying(){
            navigator.clipboard.writeText(document.getElementById("toCopy").textContent).then(() => this.copied = true);
            setTimeout(() => {
                this.copied= !this.copied;
            }, 5000);
        },
        stepperShow(index){
            let showBorder = false;
            const item = this.traceDetails[index];
            if(item.field_name !== 'Delivery'){
                showBorder = true;
            }
            return showBorder;
        },
        changeVersion(isCurrent)  {
            this.$emit('change-version', isCurrent);
        },
    }
};
</script>
<style scoped>
    #app {
        all: unset;
    }
    .theme--light.v-application {
        all: unset;
    }
    .v-application {
        all: unset;
    }
    .v-application::v-deep .v-application--wrap {
        all: unset;
    }
    .container{
        max-width: 100vw;
        height: 100%;
        min-height: 100vh;
    }
    .parent-div {
        background: #FFFFFF;
    }
    .parent-container {
        background: #FFFFFF;
        box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
        border-radius: 7px;
        margin: auto;
        width: 100%;
        min-height: 100vh;
        justify-content: center;
        display: flex;
        overflow-y: auto;
    }
    .child-container{
        margin-top: 15px;
        width: 95%;
        /* height: 100vh;    */
    }
    .progress {
        margin-top: 20%;
        margin-left: 50%;
        height: 50%;
    }
    .powered-by {
        float: right;
        text-align: right;
        width: 115px;
        margin-right: -10px;
    }
    .powered-by-label {
        text-align: left;
        font-size: 12px;
        margin-bottom: -3px;
        color: #473068;
    }
    .buyer-logo{
        float: left;
        max-width: 115px;
        margin: 10px 0px;
    }
    .product-name {
        text-align: left;
        margin: 0px 10px 15px 0px;
        font-weight: 700;
        font-size: 24px;
        line-height: 38px;
        color: #473068;
        overflow-wrap: anywhere;
    }

    .hr_devider_horizontal{
        background: linear-gradient(to right, #473068, rgba(71, 48, 104, 0));
        height: 3px;
        width: 95%;
        border: none;
        margin: 0px 0px 5px 3px;
        overflow: auto;
    }

    .traceability {
        text-align: left;
        font-weight: 700;
        font-size: 28px;
        line-height: 38px;
        color: #70D0CE;
    }

    .supplier-status {
        display: inline-block;
        margin: 15px 20px 15px 0px;
        font-size: 18px;
    }
    .verified-certification {
        display: inline-block;
        margin: 15px 20px 15px 0px;
        font-size: 18px;
    }
    .trace-container {
        margin-left: 15px;
        text-align: left;
    }
    .stepper {
        border-image-source: linear-gradient(to bottom, #796694, rgba(71, 48, 104, 0)) !important;
        margin: 21px -43px 30px 40px !important;
        min-height: 50px;
        height: auto !important;
        border-left: 3px solid;
        border-image-slice: 1;
        margin: 100px;
    }

    .stepperT {
        border-image-source: linear-gradient(to bottom, #796694, rgba(71, 48, 104, 0)) !important;
        margin: 21px -43px 30px 40px !important;
        min-height: 50px;
        height: auto !important;
        border-left: 3px solid;
        border-image-slice: 1;
        margin: 100px;
    }

    .content {
        margin: 0px 0px 25px 70px !important;
        font-size: 14px;
    }
    .color_stroke::v-deep .md-progress-spinner-circle{
        stroke: #492a67 !important;
    }
    .statuses{
        text-align: center;
        margin: 30px 0px 30px 20px;
    }
    .verified_icon_container{
        font-size: 36px;
        text-decoration: none !important;
        width: 59px;
        height: 59px;
        background: transparent;
        border: 2px solid rgba(71, 48, 104, 0.31);
        box-shadow: 0px 7.66234px 13.7922px rgba(64, 35, 105, 0.21);
        border-radius: 30px;
    }

    .unverified_icon_container{
        font-size: 36px;
        text-decoration: none !important;
        width: 59px;
        height: 59px;
        background: transparent;
        border: 2px solid rgba(234, 122, 102, 0.31);
        box-shadow: 0px 7.66234px 13.7922px rgba(234, 122, 102, 0.21);
        border-radius: 30px;
    }
    .icon-container{
        display:flex;
        align-items: center;
    }
    .title {
        margin-left: 10px;
        font-size: 16px;
        font-weight: bold;
    }
    .data-field {
        font-size: 16px;
        margin: 5px 0px 5px 0px;
    }
    .certifications {
        width: 200px;
        height: auto;
        margin-left: -5px;
    }
    .verified-certification-list {
        display: inline-block;
        justify-content: center;
        align-items: center;
        background: rgba(71, 48, 104, 0.1);
        border: 0.5px solid #473068;
        padding: 2px 5px 2px 5px;
        margin: 5px 5px 5px 5px;
        border-radius: 3px;
        color: #473068;

    }
    .unverified-certification-list {
        display: inline-block;
        justify-content: center;
        align-items: center;
        background-color: rgba(234, 122, 102, 0.1);
        padding: 2px 5px 2px 5px;
        margin: 5px 5px 5px 5px;
        border: 0.5px solid #EA7A66;
        border-radius: 3px;
        color: #EA7A66;
    }
    .footer {
        display: flex;
        justify-content: center;
    }

    .description-child-container{
        text-align: left !important;
        max-width: 250px !important;
    }

    .learn-more {
        overflow-x: hidden !important;
        white-space: pre-wrap !important;
        text-align: center;
    }

    .care-instruction-img{
        width: 50px;
        max-height: 50px;
        margin-right: 15px;
        margin-bottom: 10px;
    }
    .verified_title{
        margin-top: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 24px !important;
        line-height: 33px;
        color: #473068;
    }

    .unverified_title{
        margin-top: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 24px !important;
        line-height: 33px;
        color: #EA7A66;
    }

    .verified_details_subtitle{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #473068;
    }

    .unverified_details_subtitle{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #EA7A66;
    }

    .verified_details_data{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #473068;
    }

    .unverified_details_data{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #EA7A66;
    }
    .public-link{
        /* font-family: 'Open Sans'; */
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #473068;
        margin: 30px 0px 10px 0px;
        text-align: left;
    }
    .url-div{
        width: 60%;
        border: 0.5px solid;
        border-color: #501D5F !important;
        border-radius: 8px;
        padding: 6px;
        display: flex;
        align-items: center;
        height: 46px;
        margin-left: 0px;
    }
    .url-span {
        overflow: hidden;
        color : #C2AAE8;
        padding: 5px 6px 2px 5px;
        margin-right: auto;
        overflow: hidden;
    }

    .url-border{
        border-left: 0.5px solid #501D5F;
        height: 30px;
        margin-left: 4px;
        margin-right: 4px;
    }

    .copy-class{
        color: #FFFFFF;
        background-color: #70D0CE;
        border-radius: 3px;
        margin-right: 0px;
        /* float: right; */
    }
    .copied-class{
        color: #FFFFFF;
        background-color:  #473068;
        border-radius: 3px;
        margin-right: 0px;
        /* float: right; */
    }


.btn-color-mode-switch > label.btn-color-mode-switch-inner{
    cursor: pointer;
    margin: 0px;
    width: 282px;
    height: 30px;
    background: #E2DAED;
    color: #473068;
    border-radius: 26px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
    display: block;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before{
    content: attr(data-on);
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    top: 7px;
    right: 20px;
    color: #473068;

}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after{
    content: attr(data-off);
    width: 150px;
    height: 26px;
    background: #FFFFFF;
    border: 1px solid #473068;
    border-radius: 26px;
    position: absolute;
    left: 2px;
    top: 2px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #E2DAED;
    padding: 3px 0px;
}

.btn-color-mode-switch input[type="checkbox"]{
    opacity: 0;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner{
    background: #E2DAED;
    color: #473068;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after{
    content: attr(data-on);
    left: 130px;
    background: #FFFFFF;
    border: 1px solid #473068;
    box-shadow: 0px 0px 6px -2px #E2DAED;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before{
    content: attr(data-off);
    right: auto;
    left: 20px;
}
     .logo-img{
        max-width: 50px;
        margin-left: 2px;
    }

    .more-info-title{
        margin-left: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #473068;
    }

    .more-info-container{
        text-align: left;
        min-height: 50px;
    }
    .supply-chain-label{
        margin-top: 10px;
    }

    .more-info-data{
        margin: 25px 80px 0px 5px !important;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #473068;
    }
    .title-underline{
        border-bottom: 1px solid #70D0CE;
        width: fit-content;
    }
    .accept-line-breaks{
        white-space: pre-line;
    }
    .embed {
      display: flex;
      justify-content: center;
      margin-top: -24px;
    }
    .centered-and-cropped {
        width: 100%;
        height: auto;
        object-fit: contain;
        border-radius: 7px;
        border: 1px solid #442D64;
    }

</style>
