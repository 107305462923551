<template>
  <div class="dialog-content" :style="cssProps">
    <div class="qr-title">
      Download 360 Trace as a QR code
    </div>
    <div class="qr-parent-container">
      <div class="qr-container">
        <img class="qr-code" :src="qr" />
      </div>
      <span class="qr-link" v-if="trackingCode">{{ trackingCode }}</span>
    </div>
    <div class="btn-container">
      <ui-button
        small
        className="primary"
        class="download_qr_btn"
        @click="downloadQr"
      >
        Download
        <v-icon class="ml-2 icon-color">qr_code_2</v-icon>
        </ui-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "QrCode",
  props: {
    qr: {
      type: String,
      default: null
    },
    styleNumber: {
      type: String,
      default: "GeneratedQR"
    },
    centerAlign: {
      type: Boolean,
      default: false
    },
    trackingCode: {
      type: String,
      default: null
    }
  },
  data: () => ({
    active: false,
  }),
  computed: {
    cssProps(){
      return {
        '--content-margin': this.centerAlign ? 'auto' : '0px 0px 0px -6px',
        '--container-margin': this.centerAlign ? 'auto' : '0px 0px 10px 0px',
        '--content-text-align': this.centerAlign ? 'center' : 'left',
      }
    }
  },
  methods: {
    downloadQr() {
      const qrCode = document.createElement("a");
      qrCode.href = this.qr;
      qrCode.download = `QR_${this.styleNumber}.png`;
      qrCode.click();
    }
  }
}
</script>
<style scoped>
  .dialog-content {
    width: 100%;
    background-color: #FFFFFF;
  }

  .qr-title{
    /* font-family: 'Open Sans'; */
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #473068;
    margin: 0px 0px 10px 0px;
    text-align: var(--content-text-align);
  }

  .qr-parent-container {
    width: fit-content;
    height: fit-content;
    text-align: center;
    margin: var(--container-margin);
  }

  .qr-container {
    width: 145px;
    height: 145px;
    border: 1px solid #473068;
    margin: var(--container-margin);
    margin-bottom: 0px;
    display: flex;
    border-radius: 8px;
    padding: 18px;
  }
  .qr-code {
    margin: auto;
  }
  .qr-link {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #473068;
  }
  .btn-container{
    text-align: var(--content-text-align);
    margin: var(--content-margin);
  }

  .download_qr_btn{
    width:145px;
    border-radius: 3px;
  }
  .icon-color{
    color: #FFFFFF;
    margin-left: 8px;
    margin-top: -1px;
  }

</style>
