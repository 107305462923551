<template>
  <div class="bom-item-container">
    <div class="embed">
      <img
        class="centered-and-cropped"
        :src="getImage()"
        alt="bom item"
      />
    </div>
    <div :id="`bom-trace-item-${bomItem.id}`" class="bom-data-container mb-4">
      <h2 class="item-name">{{ bomItem.name }}</h2>
      <div class="bom-data"
        v-for="(supplier, index) in bomItem.suppliers"
        :key="index">
        <div v-if="supplier.city && supplier.country" :id="`bom-trace-item-${bomItem.id}-${supplier.id}-location`" class="sub-title-container">
          <location-icon class="location-icon" :fill="supplier.verified ? '#473068' : '#EA7A66'" />
          <span :id="`bom-trace-item-${bomItem.id}-${supplier.id}-location-span`" :class="`${supplier.verified ? 'sub-title-verified' : 'sub-title-unverified'} tractable`">{{ supplier.city }},{{" "}}{{ supplier.country }}</span>
        </div>

        <div v-if="supplier.certifications.length" class="certifications">
          <div :id="`bom-trace-item-${bomItem.id}-${supplier.id}-certificate-title`" class="sub-title-container">
            <certifications-icon class="certification-icon" :fill="supplier.verified ? '#473068' : '#EA7A66'" />
            <span :id="`bom-trace-item-${bomItem.id}-${supplier.id}-certificate-title-span`" :class="`${supplier.verified ? 'sub-title-verified' : 'sub-title-unverified'} tractable`">Certifications:</span>
          </div>
          <div class="certificates-container">
            <div :id="`bom-trace-item-${bomItem.id}-${supplier.id}-certificate-${certificate.id}`"
              style="max-width: 100%;"
              v-for="certificate in supplier.certifications"
              :key="certificate.id">
              <div :class="supplier.verified ? 'verified-certification-list' : 'unverified-certification-list'">
                <span :id="`bom-trace-item-${bomItem.id}-${supplier.id}-certificate-${certificate.id}-span`" class="tractable">{{ certificate.caption }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="expand-icon-container" :style="displayExpand ? 'display: flex;' : 'display: none;'">
      <div v-if="!expandWithPopup" class="expand-icon" @click="changeContainerHeight"><v-icon>{{ expanded ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon></div>
      <div v-else class="suppliers-show-btn mt-2" @click="openSupplierWindow(true)">
        <v-icon >mdi-open-in-new</v-icon>
      </div>
    </div>
    <popup-window
      :active="suppliersWindow"
      title="Suppliers Information"
      :hasBack="false"
      maxWidth="450px"
      minHeight="35vh"
      width="450px"
      titleStyle="
        font-size: 16px !important;
        margin-left: 2%;
        color: $primaryDark;
      "
      closeButtonStyle="padding: 2%;"
      noMargin
      @popup-back="openSupplierWindow(false)"
      @popup-close="openSupplierWindow(false)"
    >
      <div class="suppliers-window" >
        <div class="suppliers-container">
          <div class="bom-data-inpopup"
            v-for="(supplier, index) in bomItem.suppliers"
            :key="index">
            <div v-if="supplier.city && supplier.country" class="sub-title-container">
              <location-icon class="location-icon" :fill="supplier.verified ? '#473068' : '#EA7A66'" />
              <span :class="supplier.verified ? 'sub-title-verified' : 'sub-title-unverified'">{{ supplier.city }},{{" "}}{{ supplier.country }}</span>
            </div>

            <div v-if="supplier.certifications.length" class="certifications">
              <div class="sub-title-container">
                <certifications-icon class="certification-icon" :fill="supplier.verified ? '#473068' : '#EA7A66'" />
                <span :class="supplier.verified ? 'sub-title-verified' : 'sub-title-unverified'">Certifications:</span>
              </div>
              <div class="certificates-container">
                <div :class="supplier.verified ? 'verified-certification-list' : 'unverified-certification-list'"
                  v-for="certificate in supplier.certifications"
                  :key="certificate.id">
                  <span>{{ certificate.caption }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </popup-window>
  </div>
</template>

<script>

import * as conf from "../../conf.yml";

export default {
  name: "BomItemTrace",

  data: () => ({
    otherImage: require(`../../assets/bom/other.svg`),
    bomImages : {
      thread : require(`../../assets/bom/thread.svg`),
      button : require(`../../assets/bom/buttons.svg`),
      zipper : require(`../../assets/bom/zipper.svg`),
      drawcord : require(`../../assets/bom/drawcord.svg`),
      mainLabel : require(`../../assets/bom/main-label.svg`),
      careLabel : require(`../../assets/bom/care-label.svg`),
      handTag : require(`../../assets/bom/hang-tag.svg`),
      hangTagThread : require(`../../assets/bom/hang-tag-thread.svg`),
      packaging : require(`../../assets/bom/packaging.svg`),
      carton : require(`../../assets/bom/carton.svg`),
      body : require(`../../assets/bom/body.svg`),
      lining : require(`../../assets/bom/lining.svg`),
    },
    baseUrl:conf.default.server.host,
    inquiryId:null,
    displayExpand: false,
    expanded: false,
    containerHeightPx: 98,
    suppliersWindow: false,
  }),

  props: {
    bomItem: {
      type: Object,
      default: () => ({})
    },
    expandWithPopup: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    expanded: {
      handler: function(val) {
        this.hideHalfContents();
      },
    },
  },

  methods: {
    windowSizeReactor() {
      this.checkExpand()
      this.hideHalfContents();
    },
    openSupplierWindow(value) {
      this.suppliersWindow = value;
    },
    getImage(){
      if(this.bomItem.main_image)
        return `${this.baseUrl}/api/inquiries/file_show/${this.bomItem.main_image}`;
      else if(this.bomItem.generic_image)
        return this.bomImages[this.bomItem.generic_image];
      else
        return this.otherImage;
    },
    checkExpand(){
      this.hideHalfContents({ reset: true });
      const bomItem = document.getElementById(`bom-trace-item-${this.bomItem.id}`);
      if (bomItem.scrollHeight > this.containerHeightPx) {
        bomItem.style.maxWidth ='calc(100% - 139px - 30px)';
        this.displayExpand = true;
      } else {
        bomItem.style.maxWidth ='calc(100% - 139px)';
        this.displayExpand = false;
      }
    },
    changeContainerHeight(){
      this.hideHalfContents({ reset: true });
      const bomItem = document.getElementById(`bom-trace-item-${this.bomItem.id}`);
      if (this.expanded) {
        bomItem.style.maxHeight = `${this.containerHeightPx}px`;
        this.expanded = false;
      } else{
        bomItem.style.maxHeight = `${bomItem.scrollHeight}px`;
        this.expanded = true;
      }
    },
    hideHalfContents(options){
      const bomItemContainerBottom = document.getElementById(`bom-trace-item-${this.bomItem.id}`).getBoundingClientRect().bottom;
      for (let i = 0; i < this.bomItem.suppliers.length; i++) {
        const supplier = this.bomItem.suppliers[i];
        const locationElement = document.getElementById(`bom-trace-item-${this.bomItem.id}-${supplier.id}-location`);
        const childLocationSpan = document.getElementById(`bom-trace-item-${this.bomItem.id}-${supplier.id}-location-span`);
        if (locationElement) {
          childLocationSpan.style.webkitLineClamp = 'unset';
          locationElement.style.width = 'unset';
          locationElement.style.overflow = 'unset';
        }

        if (!options?.reset && locationElement && locationElement.getBoundingClientRect().bottom > bomItemContainerBottom) {
          this.isNeedToHideLineReducedElement(bomItemContainerBottom, locationElement, childLocationSpan);
        }

        const captionElement = document.getElementById(`bom-trace-item-${this.bomItem.id}-${supplier.id}-certificate-title`);
        const childCaptionSpan = document.getElementById(`bom-trace-item-${this.bomItem.id}-${supplier.id}-certificate-title-span`);
        if (captionElement) {
          childCaptionSpan.style.webkitLineClamp = 'unset';
          captionElement.style.width = 'unset';
          captionElement.style.overflow = 'unset';
        }

        if (!options?.reset && captionElement && captionElement.getBoundingClientRect().bottom > bomItemContainerBottom) {
          this.isNeedToHideLineReducedElement(bomItemContainerBottom, captionElement, childCaptionSpan);
        }

        for (let j = 0; j < supplier.certifications.length; j++) {
          const certificate = supplier.certifications[j];
          const certificateElement = document.getElementById(`bom-trace-item-${this.bomItem.id}-${supplier.id}-certificate-${certificate.id}`);
          const childCertificateSpan = document.getElementById(`bom-trace-item-${this.bomItem.id}-${supplier.id}-certificate-${certificate.id}-span`);
          if (certificateElement) {
            childCertificateSpan.style.webkitLineClamp = 'unset';
            certificateElement.style.width = 'unset';
            certificateElement.style.overflow = 'unset';
          }
          if (!options?.reset && certificateElement && certificateElement.getBoundingClientRect().bottom > bomItemContainerBottom) {
            this.isNeedToHideLineReducedElement(bomItemContainerBottom, certificateElement, childCertificateSpan);
          }
        }
      }
    },
    reduceLineClamp(element){
      const computedStyle = window.getComputedStyle(element);
      const paddingTop = parseInt(computedStyle.paddingTop);
      const paddingBottom = parseInt(computedStyle.paddingBottom);
      const borderTop = parseInt(computedStyle.borderTopWidth);
      const borderBottom = parseInt(computedStyle.borderBottomWidth);
      const lineHeight = parseInt(computedStyle.lineHeight);
      const boundingRect = element.getBoundingClientRect();
      const height = boundingRect.height - paddingTop - paddingBottom - borderTop - borderBottom;
      const numberOfLines = Math.round(height / lineHeight);
      const reducedByOne = numberOfLines - 1;
      if (reducedByOne > 0) {
        element.style.webkitLineClamp = reducedByOne;
        return true;
      }
      return false;
    },
    isNeedToHideLineReducedElement(containerBottom, parentElement, childSpan){
      let hideLocation = true;
      let reduceLocationLineClamp = true;
      while (reduceLocationLineClamp) {
        reduceLocationLineClamp = this.reduceLineClamp(childSpan);
        if (parentElement.getBoundingClientRect().bottom <= containerBottom) {
          reduceLocationLineClamp = false;
          hideLocation = false;
        }
      }

      if (hideLocation) {
        parentElement.style.width = '0px';
        parentElement.style.overflow = 'hidden';
        return true;
      }

      return false;
    }
  },

  created(){
    window.addEventListener("resize", this.windowSizeReactor);
  },

  mounted() {
    this.windowSizeReactor();
  },

  destroyed() {
    window.removeEventListener("resize", this.windowSizeReactor);
  },
};
</script>

<style lang="scss" scoped>
.bom-item-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  min-height: 100px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.bom-data-container {
  max-height: 102px;
  max-width: 100%;
  width: 100%;
  padding: 0px 0px 0px 12px;
  text-align: left;
  overflow-wrap: anywhere;
  overflow-y: hidden;
}

.expand-icon-container {
  height: 100px;
  margin-right: 10px;
  display: flex;
  align-items: flex-end;
}

.expand-icon {
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}

.v-icon.v-icon {
  color: $primary;
}

.bom-data {
  color: #473068;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 8px;
}
.embed {
  flex: 0 0 139px;
  height: 102px;
  border-radius: 7px;
  border: 0.5px solid #391965;
  text-align: center;
  position: relative;
}

.sub-title-container {
  display: flex;
  align-items: center;
}

.sub-title-verified {
  margin-left: 5px;
  color: #473068;
  line-height: 14px;
  margin-bottom: 2px;
  text-align: left;
  overflow-wrap: anywhere;
}

.sub-title-unverified {
  margin-left: 5px;
  color: #EA7A66;
  line-height: 14px;
  margin-bottom: 2px;
  text-align: left;
  overflow-wrap: anywhere;
}

.centered-and-cropped {
  width: 135px;
  height: 81px;
  object-fit: contain;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.item-name {
  color: #473068;
  font-size: 15px;
  margin-bottom: 2px;
}
.location-icon{
  margin-bottom: -1px;
  margin-right: 4px;
  flex: 0 0 10px;
}
.certification-icon {
  flex: 0 0 14px;
}
.certifications{
  color: #473068;
}
.certificates-container {
  display: flex;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 3px;
}
.tractable {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.certificate-details {
  color: #473068;
  background-color: #efefef;
  width: fit-content;
  font-size: 10px;
  min-height: 20px;
  height: fit-content;
  margin: 2px 2px 0px 0px;
  padding: 2px;
  border-radius: 18px;
  border: 0.5px solid #442d64;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  overflow-wrap: anywhere;
}
.verified-certification-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(71, 48, 104, 0.1);
  border: 0.5px solid #473068;
  padding: 2px 5px 2px 5px;
  margin: 0px 3px 3px 0px;
  border-radius: 3px;
  color: #473068;
  line-height: 14px;
  overflow-wrap: anywhere;
  max-width: 100%;
}
.unverified-certification-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(234, 122, 102, 0.1);
  padding: 2px 5px 2px 5px;
  margin: 0px 3px 3px 0px;
  border: 0.5px solid #EA7A66;
  border-radius: 3px;
  color: #EA7A66;
  overflow-wrap: anywhere;
  line-height: 14px;
  max-width: 100%;
}
.suppliers-show-btn {
  width: fit-content;
  height: fit-content;
  padding: 1px;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}
.suppliers-window {
  height: fit-content;
  padding: 0px 2%;
}
.suppliers-container {
  margin-top: 15px;
}
.bom-data-inpopup{
  color: #473068;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 10px;
  border-top: 1px solid rgba(71, 48, 104, 0.1);
  padding: 5px;
  padding-top: 15px;
}

</style>
